// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Enviroment } from './environment.interface';

export const environment: Enviroment = {
	production: false,
	apiUrl: 'https://dev.api.bookandgo.oskar.com.pl/api/',
	erpApiUrl: 'https://dev.api.erp.oskar.com.pl',
	googleAnalyticsToken: 'G-BH5TQC9HZJ',
	googleTagManagerToken: 'GTM-MSDJHQ4R',
	pixelId: '283951988061013',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
