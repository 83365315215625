import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Enviroment } from 'src/environments/environment.interface';

// Extra layer to be a provider of environment variable
// The cause is to be able to mock environment variable value in the unit tests

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService {
	get environment(): Enviroment {
		return environment;
	}
}
