import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsLoggedIn, selectIsMobile } from './general.selectors';
import * as generalActions from './general.actions';
import { Platform } from './general.types';

@Injectable({
	providedIn: 'root',
})
export class GeneralDataFacade {
	isLoggedIn$ = this.store.select(selectIsLoggedIn);
	isMobile$ = this.store.select(selectIsMobile);

	constructor(private readonly store: Store) {}

	updateIsLoggedIn(isLoggedIn: boolean): void {
		this.store.dispatch(generalActions.updateIsAuthenticated({ isLoggedIn }));
	}

	updatePlatform(platform: Platform): void {
		this.store.dispatch(generalActions.updatePlatform({ platform }));
	}
}
